import React, { Component } from 'react';
import styles from "./Portfolio.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import Project from "../../Components/Project";


class Portfolio extends Component {
  
  render() { 
    return ( 
      <>
        <section className={styles.container}>
            <h2 className={styles.header}>My Work</h2>
            <section className={styles.workContainer}> 
              <Project
                title="Ewe 4 You"
                description="Collaborative Client Project - React"
                Url="http://ewe4you.nology.io"
                gitHubShow="true"
                gitHubUrl="https://github.com/nology-tech/ewe4you"
                imageURL="../../assets/e4E.png"
                languages={["ReactJS"]}
              />
              <Project
                title="Movie Database"
                description="App with external API call - React"
                Url=" https://mels-movie-database.web.app"
                gitHubShow="true"
                gitHubUrl="https://github.com/melliemelski/movie-database"
                imageURL="../../assets/movie.png"
                languages={["Javascript"]}
              />
               <Project
                title="BrewDog API"
                description="App with external API call - React"
                Url="https://brewdog-ipa-api.web.app"
                gitHubShow="true"
                gitHubUrl="https://github.com/melliemelski/Brewdog-API"
                imageURL="../../assets/BrewDog.jpg"
                languages={["ReactJS"]}
              /> 
               <Project
                title="Memory Game"
                description="Nature Pairs - HTML & SCSS"
                Url="https://melliemelski.github.io/wk4-game"
                gitHubShow="true"
                gitHubUrl="https://github.com/melliemelski/wk4-game"
                imageURL="../../assets/tree.jpg"
                languages={["Javascript"]}
              /> 
              
            </section>
        </section>
      </>
     );
  }
}
 
export default Portfolio;

