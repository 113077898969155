import React, { Component } from 'react';
import styles from "./NavBar.module.scss"
import { Link } from "@reach/router";



class NavBar extends Component {
  state = { 
    isOpen: false,
    drop: false
   };

   toggleOpen = () => {
     this.setState( { isOpen: !this.state.isOpen, 
                        drop: !this.state.drop });
   }

   doDrop = () => {
    if (this.state.drop===true) {
      return <div className={styles.dropdown}>
      <Link to="home" onClick={this.toggleOpen}>Home</Link>
      <Link to="about" onClick={this.toggleOpen}>About Me</Link>
      <Link to="portfolio" onClick={this.toggleOpen}>Portfolio</Link>
      </div>
    }
   }
  
  render() { 

    return ( 
    <>
      <div className={styles.navContainer} >
        <div className={styles.burger} onClick={() => this.toggleOpen()}>
        <div className={`${styles.bar1} ${this.state.isOpen ? styles.changebar1 : ""}`}></div>
        <div className={`${styles.bar2} ${this.state.isOpen ? styles.changebar2 : ""}`}></div>
        <div className={`${styles.bar3} ${this.state.isOpen ? styles.changebar3 : ""}`}></div>
        </div>
        {this.doDrop()}
      </div>
    </> 
    );
  }
}
 
export default NavBar;