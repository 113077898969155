import React, { Component } from 'react';
import styles from "./About.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGitSquare,
  faHtml5,
  faCss3Alt,
  faJsSquare,
  faSass,
  faReact,
  faYarn,
} from "@fortawesome/free-brands-svg-icons";

class About extends Component {
render () {
  return (
    <>
    <h1 className={styles.header}>About Me</h1>
    <div className={styles.aboutContainer}>
      <section>
        <h3>Bootcamp</h3>
        <p className={styles.text}>
          I have just completed a 12-week intensive course with _Nology which was held entirely remotely. The course covered many aspects of being a developer, from Agile ways of working as a cohesive team-player to best practices to ways to approach problems. This has given me great confidence to join any team and to learn new languages and keep building on my skill-set. 
        </p>
        <br></br>
        <h3>Experience</h3>
        <p className={styles.text}>
          I also have many years experience working in live television graphics, which has involved working with new teams all over the world and problem solving in a high pressure environment.
        </p>
        <br></br>
        <h3>Skills include</h3>
        <div className={styles.icons}>
          <FontAwesomeIcon icon={faGitSquare}/>
          <FontAwesomeIcon icon={faHtml5}/>
          <FontAwesomeIcon icon={faCss3Alt}/>
          <FontAwesomeIcon icon={faJsSquare}/>
          <FontAwesomeIcon icon={faSass}/>
          <FontAwesomeIcon icon={faReact}/>
          <FontAwesomeIcon icon={faYarn}/>
          </div>
      </section>
    </div>
    </>
  )}     
} 
export default About;